.favorite-tech {
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.favorite-tech p {
    margin: 0;
}

.tech-wrapper {
    display: flex;
}

.tech-experience {
    width: 100%;
    height: fit-content;
    margin-left: 1rem;
    align-self: center;
}

.tech-logo {
    max-height: 100px;
}

.tech-bar {
    position: relative;
    background: #ccc;
    height: 42px;
    border-radius: 3px;
}

@media only screen and (max-width: 767px) {
    .favorite-tech {
        display: flex;
        flex-direction: column;
    }

    .favorite-tech>div {
        margin-bottom: 3rem;
    }
}
