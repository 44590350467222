.justified-text {
    text-align: justify;
}

body::-webkit-scrollbar {
    width: 10px;
    background-color: #2B2B2B;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #2B2B2B;
}

body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #F06000;
}
